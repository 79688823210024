import React from 'react'

import email from "../Assets/email.png"
import linkedin from "../Assets/linkedin.png"

function Lowbar() {
  return (
    <div style={{textAlign:'center',backgroundColor:'#a80404',color:'white',padding:'15px 0px',position:'absolute',bottom:'0',left:'0',width:'100%'}}>
      <span style={{fontSize:'1.2rem'}}><img src={email} alt="j k & sons financial advisory service" style={{marginRight:'10px'}}/>j.k.financialadvisoryservice24@gmail.com</span><br/>
      <div style={{marginTop:'5px'}}>
        <a href='https://www.linkedin.com/in/j-k-sons-financial-advisory-service-b1a892304?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BDvCWBEH2SReysxVh6JMBgQ%3D%3D' target='_blank' rel="noreferrer"><img src={linkedin} alt="" style={{height:'25px',width:'25px'}}/></a>
      </div>
      
    </div>
  )
}

export default Lowbar
